import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Modal, Form, Input, Select, Button, notification } from "antd";
import isEmpty from "lodash/isEmpty";
import _get from "lodash/get";
import _startCase from "lodash/startCase";
import InfoPopup from "./PopupForm/InfoPopup";
import SecondScreen from "./PopupForm/SecondScreen";
import ThirdScreen from "./PopupForm/ThirdScreen";
import { Context } from "../utils/Context";

const FormItem = Form.Item;
const Option = Select.Option;

const apiRequest =
  process.env.NODE_ENV === "production"
    ? "https://www.entrancezone.com/api/v1/apply/api-data"
    : "https://beta.entrancezone.com/api/v1/apply/api-data";

const userInfo = ["name", "email", "phone", "state", "city"];

const PopUpForm = (props) => {
  const { popupCollegeData, storePopupData, func_appliedIds } = useContext(
    Context
  );
  const {
    form: { getFieldDecorator, resetFields, setFieldsValue, validateFields },
    popupFormData = {},
    showForm,
    func_setShowModal,
  } = props;
  const [showPopup, setShowPopup] = useState(showForm || false);
  const [fieldKey, setFieldKey] = useState(undefined);
  const [selectedResponse, setSelectedResponse] = useState({});
  const [city, setCity] = useState([]);
  const [pageId, setPageId] = useState(undefined);
  const [secondScreenData, setSecondScreenData] = useState([]);
  const [clgInfoData, setClgInfoData] = useState(popupFormData);
  const [currentScreen, setCurrentScreen] = useState(1);

  const [loading, setLoading] = useState(false);
  const { is_api_available, id } = popupFormData || {};

  const data = popupCollegeData?.college_data?.length
    ? popupCollegeData?.college_data.find((item) => item.exam_id == pageId) ??
      {}
    : {};

  const dynamic_fields = _get(data, "dynamic_fields") || {};
  const clg_id = _get(data, "college_id") || "";
  const clg_name = _get(data, "college_name") || "";
  const { state_city = [] } = popupCollegeData || {};

  useEffect(() => {
    setPageId(id);
    setClgInfoData(popupFormData);
    setShowPopup(showForm);
    if (!is_api_available) return;
    readCookie();
    // if (isEmpty(data)) fetchData();
  }, [id, showForm]);

  // Create Cookie for Storing User Information
  const createCookie = (obj) => {
    if (isEmpty(obj)) return;
    const entries = Object.entries(obj);
    for (const [key, value] of entries) {
      let cookie = escape(key) + "=" + escape(value) + ";";
      document.cookie = cookie;
    }
  };

  // Read Cookie for Retrieving User Information
  // then Set User Info
  const readCookie = () => {
    let cookieData = {};

    let cookies = document.cookie.split("; ");
    cookies.forEach((item) => {
      userInfo.forEach((key) => {
        if (item.includes(key)) {
          const key = item.split("=")?.[0] ?? "";
          const value = item.split("=")?.[1] ?? "";
          return (cookieData[key] = value.replace(/%20/g, " "));
        }
      });
    });

    // Set Screen Third
    if (isEmpty(cookieData)) return;
    setCurrentScreen(3);

    // setFieldsValue({
    //   name: _get(cookieData, "name") || undefined,
    //   email: _get(cookieData, "email") || undefined,
    //   phone: _get(cookieData, "phone") || undefined,
    //   state: _get(cookieData, "state") || undefined,
    //   city: _get(cookieData, "city") || undefined,
    // });
  };

  const fetchData = async () => {
    try {
      const res = await axios.get(apiRequest, {
        params: { exam_id: pageId },
      });
      const responseData = res?.data?.data ?? {};
      storePopupData(pageId, responseData);
    } catch (error) {
      setShowPopup(false);
    }
  };

  const handleSelectedItem = (key, value) => {
    const { depends_on = [] } = dynamic_fields.values[key] || {};

    const handleRemoveItem = (item) => {
      setFieldsValue({ [`${item}`]: undefined });
      delete selectedResponse[item];
      setSelectedResponse(selectedResponse);
      return;
    };

    dynamic_fields.fields.map((item) =>
      item === key || depends_on.includes(item) ? null : handleRemoveItem(item)
    );

    selectedResponse[key] = value;
    setSelectedResponse(selectedResponse);
  };

  const handleCities = (e) => {
    const cities = state_city.find((item) => item.state === e) || {};
    setCity(cities.city || []);
    setFieldsValue({ city: undefined });
  };

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    const values = await validateFields();
    try {
      const { name, email, phone, state, city } = values;
      createCookie({ name, email, phone, state, city });
      values["page_source"] = "https://apply.entrancezone.com";
      values["college_id"] = clg_id;
      values["college_name"] = clg_name;
      values["exam_id"] = pageId;
      setLoading(true);

      const res = await axios.post(apiRequest, values);
      setLoading(false);
      setSecondScreenData(res?.data?.data ?? []);
      setCurrentScreen(2);
      func_appliedIds(pageId);
      // setShowPopup(false);
      // func_setShowModal(false);
      // notification.success({
      //   message: res?.data?.message ?? "Form Submitted Successfully",
      // });
      setFieldKey(undefined);
      resetFields();
    } catch (error) {
      setLoading(false);
      setShowPopup(false);
      setFieldKey(undefined);
      func_setShowModal(false);
      resetFields();
      notification.error({
        message:
          error?.response?.data?.message ??
          "Something went wrong. Please try again later",
      });
    }
  };

  const validateMobileNo = (rule, value, callback) => {
    if (isNaN(value)) {
      callback("!! No. should only be in digits !!");
      return;
    }
    callback();
  };

  const onEdit = (data = {}) => {
    setCurrentScreen(1);
    setPageId(pageId);
    setTimeout(() => {
      setFieldsValue({
        name: _get(data, "name") || undefined,
        email: _get(data, "email") || undefined,
        phone: _get(data, "phone") || undefined,
        state: _get(data, "state") || undefined,
        city: _get(data, "city") || undefined,
      });
    }, 0);
  };

  const resultantData =
    fieldKey && !isEmpty(dynamic_fields)
      ? dynamic_fields.values[fieldKey].values
          .filter((dataItem) =>
            dynamic_fields.values[fieldKey].depends_on.every(
              (dependentItem) =>
                selectedResponse[dependentItem] === dataItem[dependentItem]
            )
          )
          .map((result) => result.name)
      : [];

  return (
    <div className="popup">
      <Modal
        className="popup-form-modal"
        // title="Apply Now Form"
        width={1000}
        visible={showPopup}
        onCancel={() => {
          setShowPopup(false);
          resetFields();
          setFieldKey(undefined);
          func_setShowModal(false);
        }}
        footer={null}>
        <div className="popup__block">
          {currentScreen === 1 ? (
            <>
              <InfoPopup clg_info={clgInfoData} clg_name={clg_name} />
              <Form className="popup-form">
                <FormItem label="Name">
                  {getFieldDecorator("name", {
                    rules: [{ required: true, message: "Enter your Name" }],
                  })(<Input placeholder="Enter your Name" />)}
                </FormItem>
                <FormItem label="Email">
                  {getFieldDecorator("email", {
                    rules: [
                      {
                        required: true,
                        type: "email",
                        message: "Enter your Email",
                      },
                    ],
                  })(<Input placeholder="Enter your Email" />)}
                </FormItem>
                <FormItem label="Phone">
                  {getFieldDecorator("phone", {
                    rules: [
                      {
                        required: true,
                        message: "Phone is required",
                      },
                      {
                        len: 10,
                        type: "string",
                        message: "Please enter 10 digit number",
                      },
                      {
                        validator: validateMobileNo,
                      },
                    ],
                  })(
                    <Input
                      placeholder="Enter your Number"
                      addonBefore="+91 - "
                      maxLength={10}
                    />
                  )}
                </FormItem>
                <FormItem label="State">
                  {getFieldDecorator("state", {
                    rules: [{ required: true, message: "Select State" }],
                  })(
                    <Select
                      placeholder="Select State"
                      notFoundContent={null}
                      onSelect={(e) => handleCities(e)}
                      showSearch
                      optionLabelProp="children">
                      {state_city.length
                        ? state_city.map((item, index) => {
                            return (
                              <Option value={item.state} key={`state${index}`}>
                                {item.state}
                              </Option>
                            );
                          })
                        : null}
                    </Select>
                  )}
                </FormItem>
                <FormItem label="City">
                  {getFieldDecorator("city", {
                    rules: [{ required: true, message: "Select City" }],
                  })(
                    <Select
                      placeholder="Select City"
                      notFoundContent="Select State First"
                      showSearch
                      optionLabelProp="children">
                      {city.length
                        ? city.map((item, index) => {
                            return (
                              <Option value={item} key={`city${index}`}>
                                {item}
                              </Option>
                            );
                          })
                        : null}
                    </Select>
                  )}
                </FormItem>
                {!isEmpty(dynamic_fields) && dynamic_fields?.fields?.length
                  ? dynamic_fields.fields.map((item, index) => {
                      return (
                        <FormItem label={`${_startCase(item)}`} key={index}>
                          {getFieldDecorator(`${item}`, {
                            rules: [
                              {
                                required: true,
                                message: `Enter your ${_startCase(item)}`,
                              },
                            ],
                          })(
                            <Select
                              placeholder={`Select ${_startCase(item)}`}
                              onSelect={(e) => handleSelectedItem(item, e)}
                              onFocus={() => setFieldKey(item)}
                              showSearch
                              optionLabelProp="children"
                              dropdownClassName="custom-dropdown"
                              notFoundContent={null}>
                              {resultantData.length
                                ? resultantData.map((item, index) => {
                                    return (
                                      <Option value={item} key={index}>
                                        {item}
                                      </Option>
                                    );
                                  })
                                : null}
                            </Select>
                          )}
                        </FormItem>
                      );
                    })
                  : null}
                <Button
                  type="primary"
                  loading={loading}
                  onClick={handleSubmitForm}
                  style={{
                    gridColumn: "1/-1",
                    backgroundColor: "#f08143",
                    borderColor: "#f08143",
                    marginTop: "20px",
                  }}>
                  Submit
                </Button>
              </Form>
            </>
          ) : null}

          {/* Second Screen */}
          {currentScreen === 2 ? (
            <SecondScreen
              data={secondScreenData}
              setPageId={setPageId}
              setCurrentScreen={setCurrentScreen}
              exam_id={pageId}
              setShowPopup={setShowPopup}
              func_setShowModal={func_setShowModal}
              setClgInfoData={setClgInfoData}
            />
          ) : null}

          {currentScreen === 3 ? (
            <ThirdScreen
              clg_info={clgInfoData}
              clg_name={clg_name}
              popupCollegeData={data}
              dynamic_fields={dynamic_fields}
              setShowPopup={setShowPopup}
              func_setShowModal={func_setShowModal}
              clg_id={clg_id}
              exam_id={pageId}
              setCurrentScreen={setCurrentScreen}
              onEdit={onEdit}
            />
          ) : null}
        </div>
      </Modal>
    </div>
  );
};

export default Form.create()(PopUpForm);

{
  /* <div className="popup__featured-image">
<div
  className="popup__background-image"
  style={{
    height: "260px",
    width: "260px",
  }}>
  <div className="ad-modal__image">
    <img
      src={featured_image}
      alt={featured_alt_text}
      title={featured_title}
      className="modal__image--1"
      style={{
        height: "260px",
        width: "260px",
        objectFit: "cover",
      }}
    />
  </div>
</div>
</div> */
}
