import React, { useEffect, useContext } from "react";
import { notification } from "antd";
import SingleCard from "./SingleCard";
import { Context } from "../../utils/Context";

const SecondScreen = ({
  data = [],
  setPageId,
  setCurrentScreen,
  setShowPopup,
  func_setShowModal,
  setClgInfoData,
}) => {
  const { appliedIds = [] } = useContext(Context);
  const applyData = data.filter((item) => !appliedIds.includes(item.id));

  useEffect(() => {
    if (applyData.length === 0) {
      setShowPopup(false);
      func_setShowModal(false);
      notification.success({ message: "Form submitted successfully" });
    }
  }, []);

  return (
    <div className="second-popup">
      <div className="background-image">
        <div className="image-section">
          <img
            src={require("../../img/second_screen_popupForm.png")}
            alt=""
            className=""
            style={{ width: "100%", height: "100%" }}
          />
        </div>
        <div className="text-section">
          <h3 className="heading">
            Application successfully has been submitted
          </h3>
          <p className="text">THANK YOU !</p>
        </div>
      </div>
      <div className="cards">
        <h3
          style={{
            gridColumn: "1/-1",
            color: "#3f51b5",
            textAlign: "center",
            fontSize: "18px",
          }}>
          Similar colleges to apply for:
        </h3>
        {applyData.map((item, index) => {
          return (
            <SingleCard
              key={index}
              data={item}
              setPageId={setPageId}
              setCurrentScreen={setCurrentScreen}
              setClgInfoData={setClgInfoData}
            />
          );
        })}
      </div>
    </div>
  );
};

export default SecondScreen;

// Temporary Data

// const applyData = [
//   {
//     id: 16,
//     short_name: "LPUNEST",
//     url: "http://bit.ly/35guX8P",
//     display_name: "Lovely Professional University",
//     description:
//       "Lovely Professional University strongly believes in the fact that monetary constraints should not be an obstacle for a student to have access to quality education. So, the University extends Scholarship Scheme to students on various parameters. Scholarship can be availed in all the Programmes of the University, ranging from Diploma to Ph.D. Students have been awarded with scholarship worth Rs. 100+ crores in last year only.",
//     streams: [
//       "Agriculture",
//       "Architecture",
//       "Arts",
//       "Aviation",
//       "Commerce",
//       "Computer Application",
//       "Dental",
//       "Design",
//       "Education",
//       "Engineering",
//       "Hotel Management",
//       "Law",
//       "Management",
//       "Mass Communication",
//       "Medical",
//       "Paramedical",
//       "Pharmacy",
//       "Science",
//       "Veterinary Science",
//       "Vocational courses",
//     ],
//     courses: [
//       "AHS PG",
//       "AHS UG",
//       "BA",
//       "B.Arch",
//       "BBA",
//       "BCA",
//       "B.COM",
//       "B.DES",
//       "BDS",
//       "B.Ed.",
//       "BHM",
//       "BIO.TECH",
//       "BJMC",
//       "B.PHARM",
//       "BPMT",
//       "BSc",
//       "BSc Agri",
//       "BSc Avi",
//       "B.Tech",
//       "B.VOC",
//       "BVSc",
//       "LLB",
//       "LLM",
//       "MA",
//       "M.ARCH",
//       "MBA ",
//       "MBBS",
//       "MCA",
//       "M.COM",
//       "MD",
//       "M.DES",
//       "MDS",
//       "M.Ed.",
//       "MHM",
//       "MJMC",
//       " M.Optom",
//       "MPH",
//       "M.PHARM",
//       "MS",
//       "MSc",
//       "MSc Agri",
//       "MSc Avi",
//       "M.Tech",
//       "M.VOC",
//       "MVSc",
//     ],
//     logo: {
//       alt_text: "lpu-nest-logo",
//       display_url:
//         "https://storage.googleapis.com/ezap-prod/forms/16/lpu-nest-logo.png",
//       title: "lpu-nest-logo",
//     },
//     featured_image: {
//       alt_text: "lpu-campus",
//       display_url:
//         "https://storage.googleapis.com/ezap-prod/forms/16/lpu-campus.jpg",
//       title: "lpu-campus",
//     },
//     form_date: {
//       selector_type: "range",
//       selector_date: { start: "2019-09-14", end: "2020-04-15" },
//     },
//     is_api_available: true,
//   },
//   {
//     id: 144,
//     short_name: "MRNAT",
//     url:
//       "https://apply.manavrachna.edu.in/mru?utm_source=twigz&utm_medium=EZ&utm_campaign=banner",
//     display_name: "Manav Rachna University",
//     description:
//       "Manav Rachna University Admission Open for the session 2020. Manav Rachna University is among the Top 3 emerging Engineering Institutes of the country as per Times Engineering Institutes Survey 2019. It is also the second most promising and emerging institute for Placement and Research Capability. Manav Rachna University is declared as Nodal Centre for Virtual Labs, in association with IIT, Delhi. All the Engineering Programs offered under the Faculty of Engineering, MRU are approved by the AICTE.",
//     streams: [
//       "Arts",
//       "Commerce",
//       "Computer Application",
//       "Dental",
//       "Education",
//       "Engineering",
//       "Law",
//       "Management",
//       "Science",
//     ],
//     courses: [
//       "BA",
//       "BBA",
//       "BCA",
//       "B.COM",
//       "BDS",
//       "B.Ed.",
//       "BSc",
//       "B.Tech",
//       "LLB",
//       "LLM",
//       "MA",
//       "MBA ",
//       "MCA",
//       "MDS",
//       "MSc",
//       "M.Tech",
//     ],
//     logo: {
//       alt_text: "manav-rachna logo",
//       display_url:
//         "https://storage.googleapis.com/ezap-prod/forms/144/manav-rachna-logo.jpg",
//       title: "manav-rachna logo",
//     },
//     featured_image: {
//       alt_text: "manav-rachna-campus",
//       display_url:
//         "https://storage.googleapis.com/ezap-prod/forms/144/manav-rachna-campus.jpg",
//       title: "manav-rachna-campus",
//     },
//     form_date: {
//       selector_type: "range",
//       selector_date: { start: "2019-10-20", end: "2020-05-31" },
//     },
//     is_api_available: true,
//   },
//   {
//     id: 14,
//     short_name: "KIITEE",
//     url: "http://bit.ly/2RoP0hR",
//     display_name: "KIIT University",
//     description:
//       "Admission to all the courses (except MBBS,BDS,PG Medical & PG Dental) will be ONLY through KIITEE – 2020. Kalinga Institute of Industrial Technology (KIIT), Bhubaneswar Application open from 16th November 2019. Institution of Eminence by Ministry of HRD Govt. of India. ‘A’ Category University as per notification of Ministry of HRD, Govt. of India. KIIT Entrance Examination (KIITEE) is the All India Entrance Examination conducted by KIIT, Bhubaneswar for admission into various courses of KIIT",
//     streams: [
//       "Agriculture",
//       "Architecture",
//       "Arts",
//       "Aviation",
//       "Commerce",
//       "Computer Application",
//       "Dental",
//       "Design",
//       "Education",
//       "Engineering",
//       "Hotel Management",
//       "Law",
//       "Management",
//       "Mass Communication",
//       "Medical",
//       "Paramedical",
//       "Pharmacy",
//       "Science",
//       "Veterinary Science",
//       "Vocational courses",
//     ],
//     courses: [
//       "AHS PG",
//       "AHS UG",
//       "BA",
//       "B.Arch",
//       "BBA",
//       "BCA",
//       "B.COM",
//       "B.DES",
//       "BDS",
//       "B.Ed.",
//       "BHM",
//       "BIO.TECH",
//       "BJMC",
//       "B.PHARM",
//       "BPMT",
//       "BSc",
//       "BSc Agri",
//       "BSc Avi",
//       "B.Tech",
//       "B.VOC",
//       "BVSc",
//       "LLB",
//       "LLM",
//       "MA",
//       "M.ARCH",
//       "MBA ",
//       "MBBS",
//       "MCA",
//       "M.COM",
//       "MD",
//       "M.DES",
//       "MDS",
//       "M.Ed.",
//       "MHM",
//       "MJMC",
//       " M.Optom",
//       "MPH",
//       "M.PHARM",
//       "MS",
//       "MSc",
//       "MSc Agri",
//       "MSc Avi",
//       "M.Tech",
//       "M.VOC",
//       "MVSc",
//     ],
//     logo: {
//       alt_text: "kiit-university-logo",
//       display_url:
//         "https://storage.googleapis.com/ezap-prod/forms/14/kiit-university-logo.jpg",
//       title: "kiit-university-logo",
//     },
//     featured_image: {
//       alt_text: "kiit-campus",
//       display_url:
//         "https://storage.googleapis.com/ezap-prod/forms/14/kiit-campus.jpeg",
//       title: "kiit-campus",
//     },
//     form_date: {
//       selector_type: "range",
//       selector_date: { start: "2019-11-16", end: "2020-04-30" },
//     },
//     is_api_available: true,
//   },
// ];
