import React, { Component } from "react";
import { Select } from "antd";

const Option = Select.Option;

class Selector extends Component {
  render() {
    const {
      placeholder,
      handleSelectedValues,
      inputArray,
      selectedValues
    } = this.props;
    const children = [];
    for (let i = 0; i < inputArray.length; i++) {
      children.push(<Option key={inputArray[i]}>{inputArray[i]}</Option>);
    }

    return (
      <Select
        showSearch
        placeholder={placeholder}
        size="large"
        style={{ width: "100%" }}
        onChange={handleSelectedValues}
        value={selectedValues}
        tokenSeparators={[","]}>
        {children}
      </Select>
    );
  }
}

export default Selector;
