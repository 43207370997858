import React from "react";
import _get from "lodash/get";
import { Button } from "antd";
import moment from "moment";

const SingleCard = ({
  data: item,
  setPageId,
  setCurrentScreen,
  setClgInfoData,
}) => {
  const formDateTypeHandler = (formDate) => {
    var value = "";
    if (formDate) {
      const selector_date = formDate.selector_date;
      const selector_type = formDate.selector_type || "single";
      switch (selector_type) {
        case "single":
          value = moment(selector_date).format("DD MMM YYYY");
          break;
        case "multi":
          {
            const moments = selector_date.map((d) => moment(d));
            const maxDate = moment.max(moments);
            const minDate = moment.min(moments);
            const startDate = minDate.format("DD MMM");
            const EndDate = maxDate.format("DD MMM YYYY");
            value = `${startDate} - ${EndDate}`;
          }
          break;
        case "range":
          {
            const startDate = moment(selector_date.start).format("DD MMM");
            const EndDate = moment(selector_date.end).format("DD MMM YYYY");
            value = `${startDate} - ${EndDate}`;
          }
          break;
        default:
          break;
      }
      return value;
    } else return null;
  };
  const logo_display_image = _get(item, "logo.display_url") || "";
  const logo_alt_txt = _get(item, "logo.alt_txt") || "";
  const logo_title = _get(item, "logo.title") || "";
  const display_name = _get(item, "display_name") || "";
  const form_date = _get(item, "form_date") || {};
  const exam_id = _get(item, "id") || "";
  return (
    <div
      className="single-card"
      onClick={() => {
        setPageId(exam_id);
        setCurrentScreen(3);
        setClgInfoData(item);
      }}>
      <div>
        <img
          src={logo_display_image}
          alt={logo_alt_txt}
          className={logo_title}
          style={{
            borderRadius: "50%",
            width: "50px",
            height: "50px",
            border: "1px solid #f08143",
          }}
        />
      </div>
      <h4>{display_name}</h4>
      <p>
        <span style={{ color: "#707070", marginRight: "10px" }}>
          Last date:
        </span>
        {formDateTypeHandler(form_date)}
      </p>
      <Button
        type="primary"
        style={{ backgroundColor: "#3f51b5", outline: "none", border: "none" }}>
        Apply
      </Button>
    </div>
  );
};

export default SingleCard;
