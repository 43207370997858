import React, { useEffect, useState, useContext } from "react";
import { Form, Divider, Select, Button, notification } from "antd";
import isEmpty from "lodash/isEmpty";
import axios from "axios";
import _startCase from "lodash/startCase";
import InfoPopup from "./InfoPopup";
import GeneralInformation from "./GeneralInformation";
import { Context } from "../../utils/Context";

const Option = Select.Option;
const FormItem = Form.Item;
const userInfo = ["name", "email", "phone", "state", "city"];

const apiRequest =
  process.env.NODE_ENV === "production"
    ? "https://www.entrancezone.com/api/v1/apply/api-data"
    : "https://beta.entrancezone.com/api/v1/apply/api-data";

const ThirdScreen = (props) => {
  const { func_appliedIds } = useContext(Context);
  const {
    form: { getFieldDecorator, resetFields, setFieldsValue, validateFields },
    clg_info,
    clg_name,
    dynamic_fields,
    clg_id,
    exam_id,
    setShowPopup,
    func_setShowModal,
    setCurrentScreen,
    onEdit,
  } = props;

  const [userInfoData, setUserInfoData] = useState({});
  const [fieldKey, setFieldKey] = useState(undefined);
  const [selectedResponse, setSelectedResponse] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    readCookie();
  }, []);

  const readCookie = () => {
    let cookieData = {};

    let cookies = document.cookie.split("; ");
    cookies.forEach((item) => {
      userInfo.forEach((key) => {
        if (item.includes(key)) {
          const key = item.split("=")?.[0] ?? "";
          const value = item.split("=")?.[1] ?? "";
          return (cookieData[key] = value.replace(/%20/g, " "));
        }
      });
    });

    // Set User Info in State
    if (isEmpty(cookieData)) return setCurrentScreen(1);
    setUserInfoData(cookieData);
  };

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    const values = await validateFields();
    try {
      values["name"] = userInfoData["name"];
      values["email"] = userInfoData["email"];
      values["phone"] = userInfoData["phone"];
      values["state"] = userInfoData["state"];
      values["city"] = userInfoData["city"];
      values["page_source"] = "https://apply.entrancezone.com";
      values["college_id"] = clg_id;
      values["college_name"] = clg_name;
      values["exam_id"] = exam_id;

      setLoading(true);

      const res = await axios.post(apiRequest, values);
      func_appliedIds(exam_id);
      setCurrentScreen(2);
      // setShowPopup(false);
      // func_setShowModal(false);
      setFieldKey(undefined);
      resetFields();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setShowPopup(false);
      func_setShowModal(false);
      setFieldKey(undefined);
      resetFields();
      notification.error({
        message:
          error?.response?.data?.message ??
          "Something went wrong. Please try again later.",
      });
    }
  };

  const handleSelectedItem = (key, value) => {
    const { depends_on = [] } = dynamic_fields.values[key] || {};

    const handleRemoveItem = (item) => {
      setFieldsValue({ [`${item}`]: undefined });
      delete selectedResponse[item];
      setSelectedResponse(selectedResponse);
      return;
    };

    dynamic_fields.fields.map((item) =>
      item === key || depends_on.includes(item) ? null : handleRemoveItem(item)
    );

    selectedResponse[key] = value;
    setSelectedResponse(selectedResponse);
  };

  const resultantData =
    fieldKey && !isEmpty(dynamic_fields)
      ? dynamic_fields.values[fieldKey].values
          .filter((dataItem) =>
            dynamic_fields.values[fieldKey].depends_on.every(
              (dependentItem) =>
                selectedResponse[dependentItem] === dataItem[dependentItem]
            )
          )
          .map((result) => result.name)
      : [];

  return (
    <>
      <InfoPopup clg_info={clg_info} clg_name={clg_name} />
      <Form className="popup-form">
        <GeneralInformation data={userInfoData} onEdit={onEdit} />
        <Divider
          style={{
            margin: "30px 0 20px 0",
            gridColumn: "1/-1",
            backgroundColor: "#000",
          }}
        />
        <h3
          style={{
            gridColumn: "1/-1",
            color: "#3f51b5",
            marginBottom: "20px",
            letterSpacing: "1px",
          }}>{`${clg_name} Subject details`}</h3>
        {!isEmpty(dynamic_fields) && dynamic_fields?.fields?.length
          ? dynamic_fields.fields.map((item, index) => {
              return (
                <FormItem label={`${_startCase(item)}`} key={index}>
                  {getFieldDecorator(`${item}`, {
                    rules: [
                      {
                        required: true,
                        message: `Enter your ${_startCase(item)}`,
                      },
                    ],
                  })(
                    <Select
                      placeholder={`Select ${_startCase(item)}`}
                      onSelect={(e) => handleSelectedItem(item, e)}
                      onFocus={() => setFieldKey(item)}
                      dropdownClassName="custom-dropdown"
                      showSearch
                      optionLabelProp="children"
                      notFoundContent={null}>
                      {resultantData.length
                        ? resultantData.map((item, index) => {
                            return (
                              <Option value={item} key={index}>
                                {item}
                              </Option>
                            );
                          })
                        : null}
                    </Select>
                  )}
                </FormItem>
              );
            })
          : null}
        <Button
          type="primary"
          loading={loading}
          onClick={handleSubmitForm}
          style={{
            gridColumn: "1/-1",
            backgroundColor: "#f08143",
            borderColor: "#f08143",
            height: "40px",
            marginTop: "20px",
          }}>
          Submit
        </Button>
      </Form>
    </>
  );
};

export default Form.create()(ThirdScreen);
