import React from "react";
import LazyLoad from "react-lazyload";
import _get from "lodash/get";

const InfoPopup = ({ clg_info, clg_name }) => {
  const display_name = _get(clg_info, "display_name") || "";
  const short_name = _get(clg_info, "short_name") || "";
  const featured_image = _get(clg_info, "featured_image.display_url") || "";
  const featured_alt_text = _get(clg_info, "featured_image.alt_text") || "";
  const featured_title = _get(clg_info, "featured_image.title") || "";
  const logo_image = _get(clg_info, "logo.display_url") || "";
  const logo_alt_text = _get(clg_info, "logo.alt_text") || "";
  const logo_title = _get(clg_info, "logo.title") || "";
  const description = _get(clg_info, "description") || "";

  return (
    <div className="info-section">
      <div className="logo-image">
        <img
          src={logo_image}
          alt={logo_alt_text}
          title={logo_title}
          className=""
          style={{
            width: "60px",
            height: "60px",
            borderRadius: "50%",
            objectFit: "contain",
            border: "1px solid #f08143",
          }}
        />
        <div className="heading">
          <h3
            className="heading-3"
            style={{
              marginBottom: "6px",
              color: "#f08143",
              fontSize: "18px",
            }}>
            Register Now to Apply
          </h3>
          <p style={{ color: "#333", fontSize: "16px" }}>
            {clg_name || display_name || short_name}
          </p>
        </div>
      </div>
      <div style={{ marginBottom: "30px" }} className="featured-image">
        <LazyLoad height={150} once>
          <img
            src={featured_image}
            alt={featured_alt_text}
            title={featured_title}
            className=""
            style={{
              borderRadius: "10px",
              width: "100%",
              height: "150px",
              objectFit: "cover",
            }}
          />
        </LazyLoad>
      </div>
      <p className="para-text">
        Admission Open for {clg_name || display_name || short_name} 2020
      </p>
    </div>
  );
};

export default InfoPopup;
