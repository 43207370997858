// import React, { useState } from "react";
// import { Card, Icon, Avatar } from "antd";
// import { OutboundLink } from "react-ga";
// import moment from "moment";
// import LazyLoad from "react-lazyload";
// import PopupForm from "./PopupForm";
// const { Meta } = Card;

// const ExamCard = ({ item, isMobile, loading }) => {
//   const [showModal, setShowModal] = useState(false);

//   const formDateTypeHandler = (formDate) => {
//     var value = "";
//     if (formDate) {
//       const selector_date = formDate.selector_date;
//       const selector_type = formDate.selector_type || "single";
//       switch (selector_type) {
//         case "single":
//           value = moment(selector_date).format("DD MMM YYYY");
//           break;
//         case "multi":
//           {
//             const moments = selector_date.map((d) => moment(d));
//             const maxDate = moment.max(moments);
//             const minDate = moment.min(moments);
//             const startDate = minDate.format("DD MMM");
//             const EndDate = maxDate.format("DD MMM YYYY");
//             value = `${startDate} - ${EndDate}`;
//           }
//           break;
//         case "range":
//           {
//             const startDate = moment(selector_date.start).format("DD MMM");
//             const EndDate = moment(selector_date.end).format("DD MMM YYYY");
//             value = `${startDate} - ${EndDate}`;
//           }
//           break;
//         default:
//           break;
//       }
//       return value;
//     } else return null;
//   };

//   const {
//     short_name,
//     description,
//     featured_image = {},
//     logo,
//     url,
//     form_date,
//     display_name,
//     is_api_available,
//   } = item;

//   if (loading) return <Card loading />;

//   const cardLayout = (
//     <Card
//       className={isMobile ? "cardLayout" : undefined}
//       cover={
//         <div style={{ position: "relative" }}>
//           <LazyLoad>
//             <img
//               alt={
//                 featured_image && featured_image.alt_text
//                   ? featured_image.alt_text
//                   : ""
//               }
//               src={
//                 featured_image && featured_image.display_url
//                   ? featured_image.display_url
//                   : ""
//               }
//               className={`card__bg-image ${
//                 isMobile ? "card__bg-image-mobile" : "card__bg-image-desktop"
//               }`}
//             />
//           </LazyLoad>
//           {form_date ? (
//             <div
//               style={{
//                 position: "absolute",
//                 bottom: "4px",
//                 right: "4px",
//                 margin: "0",
//                 background: "whitesmoke",
//                 padding: "1px 6px",
//                 borderRadius: "2px",
//               }}>
//               <Icon
//                 type="calendar"
//                 style={{
//                   color: "#3F51B5",
//                   fontSize: "12px",
//                 }}
//               />
//               <span
//                 style={{
//                   fontSize: "12px",
//                   letterSpacing: "-0.6px",
//                   marginLeft: "3px",
//                   fontWeight: 500,
//                 }}>
//                 {formDateTypeHandler(form_date)}
//               </span>
//             </div>
//           ) : null}
//         </div>
//       }
//       actions={[<p style={{ color: "white" }}>Apply Now</p>]}
//       hoverable
//       bordered={false}>
//       <Meta
//         style={{ display: "flex", alignItems: "center" }}
//         title={display_name || short_name}
//         avatar={
//           <LazyLoad>
//             <Avatar src={logo.display_url} size="large" />
//           </LazyLoad>
//         }
//       />

//       <p
//         style={{
//           display: "inline-block",
//           margin: "10px 0px",
//           height: "100px",
//           overflow: "hidden",
//           whiteSpace: "pre-wrap",
//           textOverflow: "ellipsis",
//           fontSize: "0.8rem",
//         }}>
//         {description}
//       </p>
//     </Card>
//   );

//   if (is_api_available) {
//     return (
//       <>
//         <div onClick={() => setShowModal(true)}>{cardLayout}</div>
//         <PopupForm
//           showForm={showModal}
//           func_setShowModal={setShowModal}
//           popupFormData={item}
//         />{" "}
//       </>
//     );
//   }

//   return (
//     <OutboundLink
//       eventLabel={`Lead - ${short_name}`}
//       to={url}
//       target="_blank"
//       rel="nofollow noopener">
//       {cardLayout}
//     </OutboundLink>
//   );
// };

// export default ExamCard;

import React, { useState } from "react";
import { Card, Divider, Icon, Avatar, Button } from "antd";
import { OutboundLink } from "react-ga";
import moment from "moment";
import LazyLoad from "react-lazyload";
import PopupForm from "./PopupForm";
const { Meta } = Card;

const ExamCard = ({ item, isMobile, loading }) => {
  const [showModal, setShowModal] = useState(false);

  const formDateTypeHandler = (formDate) => {
    var value = "";
    if (formDate) {
      const selector_date = formDate.selector_date;
      const selector_type = formDate.selector_type || "single";
      switch (selector_type) {
        case "single":
          value = moment(selector_date).format("DD MMM YYYY");
          break;
        case "multi":
          {
            const moments = selector_date.map((d) => moment(d));
            const maxDate = moment.max(moments);
            const minDate = moment.min(moments);
            const startDate = minDate.format("DD MMM");
            const EndDate = maxDate.format("DD MMM YYYY");
            value = `${startDate} - ${EndDate}`;
          }
          break;
        case "range":
          {
            const startDate = moment(selector_date.start).format("DD MMM");
            const EndDate = moment(selector_date.end).format("DD MMM YYYY");
            value = `${startDate} - ${EndDate}`;
          }
          break;
        default:
          break;
      }
      return value;
    } else return null;
  };

  const {
    short_name,
    description,
    featured_image = {},
    logo,
    url,
    form_date,
    display_name,
    is_api_available,
  } = item;

  if (loading) return <Card loading />;

  const cardLayout = (
    <div className="exam-card">
      <div>
        <div style={{ padding: "3px" }}>
          <LazyLoad height={120} once>
            <img
              alt={
                featured_image && featured_image.alt_text
                  ? featured_image.alt_text
                  : ""
              }
              src={
                featured_image && featured_image.display_url
                  ? featured_image.display_url
                  : ""
              }
              className="featured-image"
            />
          </LazyLoad>
        </div>
        <div className="college-name">
          <div>
            <img src={logo.display_url} className="logo" />
          </div>
          <div style={{ width: "100%" }}>
            <h5 className="" style={{ fontSize: "16px" }}>
              {display_name || short_name}
            </h5>
            <Divider style={{ margin: "8px 0" }} />
            {form_date ? (
              <div>
                <div>
                  <Icon
                    type="calendar"
                    style={{
                      color: "#3F51B5",
                      fontSize: "12px",
                    }}
                  />
                  <span
                    style={{
                      fontSize: "12px",
                      letterSpacing: "-0.6px",
                      marginLeft: "3px",
                      fontWeight: 500,
                      color: "#3F51B5",
                    }}>
                    {formDateTypeHandler(form_date)}
                  </span>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <div
          style={{
            display: "grid",
            marginTop: "15px",
          }}>
          <p
            style={{
              fontSize: "12px",
              height: "94px",
              overflow: "hidden",
              color: "#4a4a4a",
              letterSpacing: ".5px",
            }}>
            {description}
          </p>
        </div>
      </div>

      <Button
        style={{
          backgroundColor: "#3f51b5",
          marginTop: "20px",
          height: "40px",
          color: "white",
          alignSelf: "flex-end",
        }}>
        Apply Now
      </Button>
    </div>

    // <div
    //   style={{
    //     boxShadow: "0 2px 6px 0 rgba(0, 0, 0, 0.16)",
    //     borderRadius: "5px",
    //   }}>
    //   <div>
    //     <LazyLoad>
    //       <img
    //         alt={
    //           featured_image && featured_image.alt_text
    //             ? featured_image.alt_text
    //             : ""
    //         }
    //         src={
    //           featured_image && featured_image.display_url
    //             ? featured_image.display_url
    //             : ""
    //         }
    //         className={`card__bg-image ${
    //           isMobile ? "card__bg-image-mobile" : "card__bg-image-desktop"
    //         }`}
    //       />
    //     </LazyLoad>
    //   </div>
    //   <div
    //     style={{
    //       display: "grid",
    //       gridTemplateColumns: "1fr 1fr",
    //       alignItems: "center",
    //     }}>
    //     <div style={{ display: "grid" }}>
    //       <div
    //         style={{
    //           // paddingLeft: "20px",
    //           transform: "translate(20px,-50%)",
    //           backgroundColor: "#fff",
    //           boxShadow: "0 2px 6px 0 rgba(0, 0, 0, 0.16)",
    //           justifySelf: "flex-start",
    //         }}>
    //         <LazyLoad>
    //           <img
    //             src={logo.display_url}
    //             alt="logo-clg"
    //             className="card__logo"
    //             style={{ width: "100%" }}
    //           />
    //         </LazyLoad>
    //       </div>
    //     </div>
    //     {form_date ? (
    //       <div>
    //         <div
    //           style={{
    //             // position: "absolute",
    //             // bottom: "4px",
    //             // right: "4px",
    //             // margin: "0",
    //             background: "whitesmoke",
    //             padding: "1px 6px",
    //             borderRadius: "2px",
    //           }}>
    //           <Icon
    //             type="calendar"
    //             style={{
    //               color: "#3F51B5",
    //               fontSize: "12px",
    //             }}
    //           />
    //           <span
    //             style={{
    //               fontSize: "12px",
    //               letterSpacing: "-0.6px",
    //               marginLeft: "3px",
    //               fontWeight: 500,
    //             }}>
    //             {formDateTypeHandler(form_date)}
    //           </span>
    //         </div>
    //       </div>
    //     ) : null}
    //   </div>
    //   <div style={{ padding: "0 20px 20px 20px" }}>
    //     <h2 style={{ fontSize: "16px", fontWeight: "500" }}>
    //       {display_name || short_name}
    //     </h2>
    //     <p
    //       style={{
    //         display: "inline-block",
    //         margin: "10px 0px",
    //         height: "108px",
    //         overflow: "hidden",
    //         whiteSpace: "pre-wrap",
    //         textOverflow: "ellipsis",
    //         fontSize: "12px",
    //         color: "rgba(0,0,0,.65)",
    //       }}>
    //       {description}
    //     </p>
    //     <Button type="primary" style={{ width: "100%", height: "40px" }}>
    //       Apply Now
    //     </Button>
    //   </div>
    // </div>

    //  <Card
    //     className={isMobile ? "cardLayout" : undefined}
    //     cover={
    //       <div style={{ position: "relative" }}>
    //         <LazyLoad>
    //           <img
    //             alt={
    //               featured_image && featured_image.alt_text
    //                 ? featured_image.alt_text
    //                 : ""
    //             }
    //             src={
    //               featured_image && featured_image.display_url
    //                 ? featured_image.display_url
    //                 : ""
    //             }
    //             className={`card__bg-image ${
    //               isMobile ? "card__bg-image-mobile" : "card__bg-image-desktop"
    //             }`}
    //           />
    //         </LazyLoad>
    //         {form_date ? (
    //           <div
    //             style={{
    //               position: "absolute",
    //               bottom: "4px",
    //               right: "4px",
    //               margin: "0",
    //               background: "whitesmoke",
    //               padding: "1px 6px",
    //               borderRadius: "2px",
    //             }}>
    //             <Icon
    //               type="calendar"
    //               style={{
    //                 color: "#3F51B5",
    //                 fontSize: "12px",
    //               }}
    //             />
    //             <span
    //               style={{
    //                 fontSize: "12px",
    //                 letterSpacing: "-0.6px",
    //                 marginLeft: "3px",
    //                 fontWeight: 500,
    //               }}>
    //               {formDateTypeHandler(form_date)}
    //             </span>
    //           </div>
    //         ) : null}
    //       </div>
    //     }
    //     actions={[<p style={{ color: "white" }}>Apply Now</p>]}
    //     hoverable
    //     bordered={false}>
    //     <Meta
    //       style={{ display: "flex", alignItems: "center" }}
    //       title={display_name || short_name}
    //       avatar={
    //         <LazyLoad>
    //           <Avatar src={logo.display_url} size="large" />
    //         </LazyLoad>
    //       }
    //     />

    //     <p
    //       style={{
    //         display: "inline-block",
    //         margin: "10px 0px",
    //         height: "100px",
    //         overflow: "hidden",
    //         whiteSpace: "pre-wrap",
    //         textOverflow: "ellipsis",
    //         fontSize: "0.8rem",
    //       }}>
    //       {description}
    //     </p>
    //   </Card>
  );

  if (is_api_available) {
    return (
      <div>
        <div onClick={() => setShowModal(true)} style={{ height: "100%" }}>
          {cardLayout}
        </div>
        <PopupForm
          showForm={showModal}
          func_setShowModal={setShowModal}
          popupFormData={item}
        />{" "}
      </div>
    );
  }

  return (
    <OutboundLink
      eventLabel={`Lead - ${short_name}`}
      to={url}
      target="_blank"
      rel="nofollow noopener">
      {cardLayout}
    </OutboundLink>
  );
};

export default ExamCard;
