import React from "react";
import logo from "./img/logo.png";
import { OutboundLink } from "react-ga";

export const Nav00DataSource = {
  wrapper: { className: "header0 home-page-wrapper" },
  page: { className: "home-page" },
  logo: {
    className: "header0-logo",
    children: logo,
    url:
      process.env.NODE_ENV === "production"
        ? "https://apply.entrancezone.com"
        : "http://localhost:3000"
  },
  Menu: {
    className: "header0-menu",
    children: [
      {
        name: "item0",
        a: {
          children: "Top Colleges",
          href: "https://www.entrancezone.com/colleges",
          target: "_blank"
        }
      },
      {
        name: "item1",
        a: {
          children: "Top Exams",
          href: "https://www.entrancezone.com/exams",
          target: "_blank"
        }
      },
      {
        name: "item2",
        a: {
          children: "Engineering",
          href: "https://www.entrancezone.com/engineering/",
          target: "_blank"
        }
      },
      {
        name: "item3",
        a: {
          children: "Admissions",
          href: "https://www.entrancezone.com/admissions/",
          target: "_blank"
        }
      }
    ]
  },
  mobileMenu: { className: "header0-mobile-menu" }
};

export const Footer10DataSource = {
  wrapper: { className: "home-page-wrapper footer1-wrapper" },
  OverPack: { className: "footer1", playScale: 0 },
  block: {
    className: "home-page",
    children: [
      {
        name: "block0",
        xs: 24,
        md: 6,
        className: "block",
        title: {
          className: "logo",
          children: logo
        },
        content: {
          className: "slogan",
          children: "Exploring Careers: KG to PhD"
        }
      },
      {
        name: "block1",
        xs: 24,
        md: 6,
        className: "block",
        title: { children: "Most Popular Exams" },
        content: {
          children: (
            <span>
              <p>
                {" "}
                <OutboundLink
                  eventLabel="Popular Exams - JEE Main 2020"
                  to="https://www.entrancezone.com/engineering/jee-main-2020/"
                  target="_blank"
                >
                  JEE Main 2020
                </OutboundLink>{" "}
              </p>{" "}
              <p>
                {" "}
                <OutboundLink
                  eventLabel="Popular Exams - JEE Advanced 2020"
                  to="https://www.entrancezone.com/engineering/jee-advanced-2020/"
                  target="_blank"
                >
                  JEE Advanced 2020
                </OutboundLink>{" "}
              </p>{" "}
              <p>
                {" "}
                <OutboundLink
                  eventLabel="Popular Exams - BITSAT 2020"
                  to="https://www.entrancezone.com/engineering/bitsat-2020/"
                  target="_blank"
                >
                  BITSAT 2020
                </OutboundLink>{" "}
              </p>{" "}
              <p>
                {" "}
                <OutboundLink
                  eventLabel="Popular Exams - WBJEEE 2020"
                  to="https://www.entrancezone.com/engineering/wbjee-2020/"
                  target="_blank"
                >
                  WBJEEE 2020
                </OutboundLink>{" "}
              </p>
              <p>
                {" "}
                <OutboundLink
                  eventLabel="Popular Exams - Manipal OET 2020"
                  to="https://www.entrancezone.com/engineering/mu-oet-2020/"
                  target="_blank"
                >
                  Manipal OET 2020
                </OutboundLink>{" "}
              </p>{" "}
              <p>
                {" "}
                <OutboundLink
                  eventLabel="Popular Exams - SRMJEE 2020"
                  to="https://www.entrancezone.com/engineering/srmjeee-2020/"
                  target="_blank"
                >
                  SRMJEE 2020
                </OutboundLink>{" "}
              </p>{" "}
              <p>
                {" "}
                <OutboundLink
                  eventLabel="Popular Exams - VITEEE 2020"
                  to="https://www.entrancezone.com/engineering/viteee-2020/"
                  target="_blank"
                >
                  VITEEE 2020
                </OutboundLink>{" "}
              </p>{" "}
              <p>
                {" "}
                <OutboundLink
                  eventLabel="Popular Exams - Find More Here."
                  to="https://www.entrancezone.com/exams"
                  target="_blank"
                >
                  Find More Here.
                </OutboundLink>{" "}
              </p>
            </span>
          )
        }
      },
      {
        name: "block2",
        xs: 24,
        md: 6,
        className: "block",
        title: { children: "Quick Links" },
        content: {
          children: (
            <span>
              <p>
                {" "}
                <OutboundLink
                  eventLabel="Quick Links - Exams Calender"
                  to="https://www.entrancezone.com/engineering/entrance-exam-dates/"
                  target="_blank"
                >
                  Exams Calender
                </OutboundLink>{" "}
              </p>{" "}
              <p>
                {" "}
                <OutboundLink
                  eventLabel="Quick Links - Board Time Table"
                  to="https://www.entrancezone.com/admissions/category/10th-12th-boards/"
                  target="_blank"
                >
                  Board Time Table
                </OutboundLink>{" "}
              </p>
              <p>
                {" "}
                <OutboundLink
                  eventLabel="Quick Links - Admit Card"
                  to="https://www.entrancezone.com/engineering/category/admit-card/"
                  target="_blank"
                >
                  Admit Card
                </OutboundLink>{" "}
              </p>{" "}
              <br />
              <p>
                {" "}
                <OutboundLink
                  eventLabel="Quick Links - Privacy Policy"
                  to="https://www.entrancezone.com/engineering/privacy-policy/"
                  target="_blank"
                >
                  Privacy Policy
                </OutboundLink>{" "}
              </p>{" "}
              <p>
                {" "}
                <OutboundLink
                  eventLabel="Quick Links - About Us"
                  to="https://www.entrancezone.com/about-us"
                  target="_blank"
                >
                  FAQ-About Us
                </OutboundLink>{" "}
              </p>{" "}
              <p>
                {" "}
                <OutboundLink
                  eventLabel="Quick Links - Contact Us"
                  to="https://www.entrancezone.com/engineering/lets-talk/"
                  target="_blank"
                >
                  Contact Us
                </OutboundLink>{" "}
              </p>
              <p>
                {" "}
                <OutboundLink
                  eventLabel="Quick Links - Disclaimer"
                  to="https://www.entrancezone.com/engineering/disclaimer/"
                  target="_blank"
                >
                  Disclaimer
                </OutboundLink>{" "}
              </p>
            </span>
          )
        }
      },
      {
        name: "block3",
        xs: 24,
        md: 6,
        className: "block",
        title: { children: "Follow Us On" },
        content: {
          children: (
            <span>
              <p>
                {" "}
                <OutboundLink
                  eventLabel="Social Media - Facebook"
                  to="https://www.facebook.com/entrancezone/"
                  target="_blank"
                >
                  Facebook
                </OutboundLink>{" "}
              </p>{" "}
              <p>
                {" "}
                <OutboundLink
                  eventLabel="Social Media - LinkedIn"
                  to="https://www.linkedin.com/company/entrancezone.com/"
                  target="_blank"
                >
                  LinkedIn
                </OutboundLink>{" "}
              </p>{" "}
              <p>
                {" "}
                <OutboundLink
                  eventLabel="Social Media - Twitter"
                  to="https://twitter.com/entrancezone"
                  target="_blank"
                >
                  Twitter
                </OutboundLink>{" "}
              </p>
            </span>
          )
        }
      }
    ]
  },
  copyrightWrapper: { className: "copyright-wrapper" },
  copyrightPage: { className: "home-page" },
  copyright: {
    className: "copyright",
    children: (
      <span>
        © {new Date().getFullYear()} by{" "}
        <a href="https://www.entrancezone.com">
          SCUBEN ECOMMERCE PRIVATE LIMITED
        </a>{" "}
        All Rights Reserved
      </span>
    )
  }
};
