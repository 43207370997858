import React, { useState } from "react";
import axios from "axios";
import _get from "lodash/get";
import { Form, Select, Input, Button, notification, Row, Col } from "antd";

const Option = Select.Option;
const FormItem = Form.Item;

const apiRequest =
  process.env.NODE_ENV === "production"
    ? "https://www.entrancezone.com/api/v1/subscribe"
    : "https://beta.entrancezone.com/api/v1/subscribe";

const stateData = [
  {
    id: 18,
    name: "Andaman and Nicobar Islands",
  },
  {
    id: 4,
    name: "Andhra Pradesh",
  },
  {
    id: 30,
    name: "Arunachal Pradesh",
  },
  {
    id: 5,
    name: "Assam",
  },
  {
    id: 2,
    name: "Bihar",
  },
  {
    id: 22,
    name: "Chandigarh",
  },
  {
    id: 6,
    name: "Chhattisgarh",
  },
  {
    id: 7,
    name: "Dadra and Nagar Haveli",
  },
  {
    id: 38,
    name: "Daman and Diu",
  },
  {
    id: 9,
    name: "Delhi",
  },
  {
    id: 11,
    name: "Goa",
  },
  {
    id: 12,
    name: "Gujarat",
  },
  {
    id: 8,
    name: "Haryana",
  },
  {
    id: 13,
    name: "Himachal Pradesh",
  },
  {
    id: 14,
    name: "Jammu and Kashmir",
  },
  {
    id: 10,
    name: "Jharkhand",
  },
  {
    id: 15,
    name: "Karnataka",
  },
  {
    id: 26,
    name: "Kerala",
  },
  {
    id: 23,
    name: "Madhya Pradesh",
  },
  {
    id: 25,
    name: "Maharashtra",
  },
  {
    id: 35,
    name: "Manipur",
  },
  {
    id: 34,
    name: "Meghalaya",
  },
  {
    id: 32,
    name: "Mizoram",
  },
  {
    id: 31,
    name: "Nagaland",
  },
  {
    id: 24,
    name: "Odisha",
  },
  {
    id: 17,
    name: "Puducherry",
  },
  {
    id: 21,
    name: "Punjab",
  },
  {
    id: 3,
    name: "Rajasthan",
  },
  {
    id: 29,
    name: "Sikkim",
  },
  {
    id: 27,
    name: "Tamil Nadu",
  },
  {
    id: 28,
    name: "Telangana",
  },
  {
    id: 20,
    name: "Tripura",
  },
  {
    id: 19,
    name: "Uttarakhand",
  },
  {
    id: 1,
    name: "Uttar Pradesh",
  },
  {
    id: 16,
    name: "West Bengal",
  },
];

const NewsLetter = (props) => {
  const {
    form: { getFieldDecorator, validateFields, setFieldsValue },
  } = props;

  const [isLoading, setIsLoading] = useState(false);

  const handleSubscribe = async (e) => {
    e.preventDefault();
    const values = await validateFields();
    try {
      setIsLoading(true);
      values["source_page"] = "https://apply.entrancezone.com";
      const response = await axios.post(apiRequest, values);
      setIsLoading(false);

      setFieldsValue({
        name: undefined,
        email: undefined,
        state_id: undefined,
        phone: undefined,
      });
      notification.success({
        message: _get(response, "data.message") || "Something went wrong",
      });
    } catch (error) {
      setIsLoading(false);
      notification.error({
        message: _get(error.response, "data.message") || "Something went wrong",
      });
    }
  };

  const validateMobileNo = (rule, value, callback) => {
    if (isNaN(value)) {
      callback(" ");
      return;
    }
    callback();
  };
  return (
    <Row type="flex" justify="center" className="news-letters">
      <Col className="news-letter">
        <div className="news-letter__content">
          <h4 className="heading-4 news-letter__content--heading">
            Subscribe to Get Updated Information
          </h4>

          <Form className="news-letter__search-bar">
            <FormItem>
              {getFieldDecorator("name", {
                rules: [{ required: true, message: "Please enter your name" }],
              })(<Input placeholder="Name" />)}
            </FormItem>
            <FormItem>
              {getFieldDecorator("email", {
                rules: [
                  {
                    required: true,
                    type: "email",
                    message: "Please enter your email",
                  },
                ],
              })(<Input placeholder="Email" />)}
            </FormItem>

            <FormItem>
              {getFieldDecorator("state_id", {
                rules: [
                  { required: true, message: "Please select your state!" },
                ],
              })(
                <Select
                  placeholder="State"
                  showSearch
                  optionFilterProp="children">
                  {stateData.map((item, index) => {
                    return (
                      <Option value={item.id} key={`s${index}`}>
                        {item.name}
                      </Option>
                    );
                  })}
                </Select>
              )}
            </FormItem>
            <FormItem>
              {getFieldDecorator("phone", {
                rules: [
                  {
                    required: true,
                    message: "Phone is required",
                  },
                  {
                    len: 10,
                    type: "string",
                    message: "Please enter 10 digit number",
                  },
                  {
                    validator: validateMobileNo,
                  },
                ],
              })(
                <Input
                  addonBefore="+91 - "
                  placeholder="Enter Your Phone No..."
                  maxLength={10}
                />
              )}
            </FormItem>
          </Form>

          <Button
            type="primary"
            className="button"
            loading={isLoading}
            onClick={handleSubscribe}>
            Subscribe Now
          </Button>
        </div>
      </Col>
    </Row>
  );
};

export default Form.create()(NewsLetter);
