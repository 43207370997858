import React, { useEffect, useState } from "react";
import ReactGA from "react-ga";
import { Button, Row, Col, Card } from "antd";
import Selector from "./Selector";

const SelectorHandler = ({ filters, dataUpdate, searchLoader, isMobile }) => {
  const [streams, setStreams] = useState([]);
  const [courses, setCourses] = useState([]);
  const [selectedStream, setSelectedStream] = useState(undefined);
  const [selectedCourse, setSelectedCourse] = useState(undefined);

  const getStreams = () => {
    const allStreams = filters.map(item => item.stream);
    setStreams(allStreams);
  };

  const getCourses = () => {
    let allCourses = [];

    if (selectedStream) {
      filters.forEach(item => {
        if (item.stream === selectedStream) {
          allCourses = item.courses;
        }
      });
    } else {
      const allCoursesWithDuplicates = [];
      filters.forEach(item => {
        allCoursesWithDuplicates.push(...item.courses);
      });
      //removing duplicates if any.
      allCourses = allCoursesWithDuplicates.filter(
        (item, index) => allCoursesWithDuplicates.indexOf(item) === index
      );
    }
    setCourses(allCourses);
  };

  const handleGoogleAnalytics = (action, label) => {
    ReactGA.event({
      category: "Stream & Course",
      action,
      label
    });
  };

  const handleStreamChange = value => {
    setSelectedStream(value);
    //set course to undefine if stream is set.
    setSelectedCourse(undefined);
    handleGoogleAnalytics("Stream Changed", `Stream -> ${value} `);
  };

  const handleCourseChange = value => {
    setSelectedCourse(value);
    handleGoogleAnalytics(
      "Course Changed",
      `Stream -> ${selectedStream}, Course -> ${value}`
    );
  };

  useEffect(() => {
    getStreams();
    getCourses();
  }, [filters]);

  useEffect(() => {
    getCourses();
    dataUpdate({ selectedStream, selectedCourse });
  }, [selectedStream, selectedCourse]);

  return (
    <Row
      type="flex"
      justify="center"
      className={isMobile ? "" : "banner__selector-desktop"}
    >
      <Col xs={22} lg={14}>
        <Card bordered={true}>
          <Row type="flex" gutter={16}>
            <Col
              xs={12}
              md={10}
              style={{ marginBottom: "8px", marginTop: "4px" }}
            >
              <Selector
                placeholder="Select a Stream"
                handleSelectedValues={handleStreamChange}
                inputArray={streams}
                selectedValues={selectedStream}
              />
            </Col>
            <Col
              xs={12}
              md={10}
              style={{ marginBottom: "8px", marginTop: "4px" }}
            >
              <Selector
                placeholder="Select a Course"
                handleSelectedValues={handleCourseChange}
                inputArray={courses}
                selectedValues={selectedCourse}
              />
            </Col>
            <Col xs={24} md={4}>
              <Button
                onClick={searchLoader}
                type="primary"
                style={{ width: "100%", height: "40px", marginTop: "4px" }}
              >
                Search
              </Button>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

export default SelectorHandler;
