// import React from "react";
// import { Row, Col, Divider } from "antd";
// import ExamCard from "./ExamCard";
// import { Flipper, Flipped } from "react-flip-toolkit";

// const ExamList = ({
//   forms,
//   selectedStream,
//   selectedCourse,
//   isMobile,
//   searchLoading,
//   dataLoading,
// }) => {
//   const updatedForm = () => {
//     if (!(selectedStream || selectedCourse)) return forms;
//     return forms.filter(
//       (form) =>
//         (selectedStream ? form.streams.includes(selectedStream) : true) &&
//         (selectedCourse ? form.courses.includes(selectedCourse) : true)
//     );
//   };

//   const fakeDataCard = [{}, {}, {}, {}, {}, {}, {}, {}];

//   const formCard = (updatedFormData) => {
//     return updatedFormData.map((item, index) => {
//       return (
//         <Flipped flipId="list" key={index}>
//           <Col xs={24} md={12} lg={6} key={index}>
//             <ExamCard
//               item={item}
//               isMobile={isMobile}
//               loading={searchLoading || dataLoading}
//             />
//           </Col>
//         </Flipped>
//       );
//     });
//   };

//   const sentenceFramed = (formCount) => {
//     if (searchLoading || dataLoading)
//       return "Please wait while we fetch results ...";
//     let result;
//     if (selectedStream && selectedCourse)
//       result = `Forms for ${selectedStream} → ${selectedCourse} ( ${formCount} Live ${
//         formCount > 1 ? "Forms" : "Form"
//       } )`;
//     else if (selectedStream)
//       result = `Forms for ${selectedStream} ( ${formCount} Live ${
//         formCount > 1 ? "Forms" : "Form"
//       } )`;
//     else if (selectedCourse)
//       result = `Forms for ${selectedCourse} ( ${formCount} Live ${
//         formCount > 1 ? "Forms" : "Form"
//       } )`;
//     else result = `All Available Forms (${formCount})`;
//     return result;
//   };

//   const updatedFormData = updatedForm();
//   const formCount = updatedFormData.length;

//   return (
//     <div>
//       <div className="u-margin-bottom-medium">
//         <Row type="flex" justify="center">
//           <Col xs={22} md={20} lg={22} xl={22}>
//             {isMobile ? (
//               <h3>{sentenceFramed(formCount)}</h3>
//             ) : (
//               <Divider>{sentenceFramed(formCount)}</Divider>
//             )}
//           </Col>
//           <Col xs={22} md={22} lg={22}>
//             <Row type="flex" gutter={[24, 24]}>
//               {searchLoading || dataLoading ? (
//                 formCard(fakeDataCard)
//               ) : (
//                 <Flipper
//                   flipKey={`list-asc-${selectedStream}-${selectedCourse}-${formCount}`}
//                   spring="veryGentle"
//                   staggerConfig={{
//                     default: {
//                       reverse: false,
//                       speed: 0.5,
//                     },
//                   }}>
//                   {formCard(updatedFormData)}
//                 </Flipper>
//               )}
//             </Row>
//           </Col>
//         </Row>
//       </div>
//     </div>
//   );
// };

// export default ExamList;

import React from "react";
import { Row, Col, Divider } from "antd";
import ExamCard from "./ExamCard";
import { Flipper, Flipped } from "react-flip-toolkit";

const ExamList = ({
  forms,
  selectedStream,
  selectedCourse,
  isMobile,
  searchLoading,
  dataLoading,
}) => {
  const updatedForm = () => {
    if (!(selectedStream || selectedCourse)) return forms;
    return forms.filter(
      (form) =>
        (selectedStream ? form.streams.includes(selectedStream) : true) &&
        (selectedCourse ? form.courses.includes(selectedCourse) : true)
    );
  };

  const fakeDataCard = [{}, {}, {}, {}, {}, {}, {}, {}];

  const formCard = (updatedFormData) => {
    return updatedFormData.map((item, index) => {
      return (
        <Flipped flipId="list" key={index}>
          <ExamCard
            item={item}
            isMobile={isMobile}
            loading={searchLoading || dataLoading}
          />
        </Flipped>
      );
    });
  };

  const sentenceFramed = (formCount) => {
    if (searchLoading || dataLoading)
      return "Please wait while we fetch results ...";
    let result;
    if (selectedStream && selectedCourse)
      result = `Forms for ${selectedStream} → ${selectedCourse} ( ${formCount} Live ${
        formCount > 1 ? "Forms" : "Form"
      } )`;
    else if (selectedStream)
      result = `Forms for ${selectedStream} ( ${formCount} Live ${
        formCount > 1 ? "Forms" : "Form"
      } )`;
    else if (selectedCourse)
      result = `Forms for ${selectedCourse} ( ${formCount} Live ${
        formCount > 1 ? "Forms" : "Form"
      } )`;
    else result = `All Available Forms (${formCount})`;
    return result;
  };

  const updatedFormData = updatedForm();
  const formCount = updatedFormData.length;

  return (
    <div>
      <div className="u-margin-bottom-medium">
        <Row type="flex" justify="center">
          <Col xs={22} md={20} lg={22} xl={22}>
            {isMobile ? (
              <h3 style={{ marginBottom: "20px" }}>
                {sentenceFramed(formCount)}
              </h3>
            ) : (
              <Divider>{sentenceFramed(formCount)}</Divider>
            )}
          </Col>
          <Col xs={23} md={22} lg={22}>
            <div className="exam-list">
              {searchLoading || dataLoading ? (
                <div className="flipper">{formCard(fakeDataCard)}</div>
              ) : (
                <Flipper
                  flipKey={`list-asc-${selectedStream}-${selectedCourse}-${formCount}`}
                  spring="veryGentle"
                  staggerConfig={{
                    default: {
                      reverse: false,
                      speed: 0.5,
                    },
                  }}
                  className="flipper">
                  {formCard(updatedFormData)}
                </Flipper>
              )}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ExamList;
