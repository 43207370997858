import React from "react";
import { Icon } from "antd";
import isEmpty from "lodash/isEmpty";
import _startCase from "lodash/startCase";

const GeneralInformation = ({ data = {}, onEdit }) => {
  if (isEmpty(data)) return <div />;
  return (
    <>
      <div
        style={{
          gridColumn: "1/-1",
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "20px",
        }}>
        <h3 style={{ color: "#3f51b5", letterSpacing: "1px" }}>
          General Information
        </h3>
        <Icon
          onClick={() => {
            onEdit(data);
          }}
          type="edit"
          theme="filled"
          style={{ color: "#f08143", cursor: "pointer", padding: "10px" }}
        />
      </div>
      <div
        style={{
          gridColumn: "1/-1",
          display: "grid",
          gridTemplateColumns: "repeat(3,1fr)",
          gap: "20px",
        }}>
        {Object.keys(data).map(function (key, index) {
          return (
            <div key={index}>
              <h4 style={{ letterSpacing: "1px", marginBottom: "3px" }}>
                {_startCase(key)}
              </h4>
              <p style={{ color: "#8d8c8c", letterSpacing: "1px" }}>
                {data[key]}
              </p>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default GeneralInformation;
