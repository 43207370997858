import React, { useState, useEffect, useContext } from "react";
import SelectorHandler from "./SelectorHandler";
import ExamList from "./ExamList";
import axios from "axios";
import isEmpty from "lodash/isEmpty";
import { Context } from "../utils/Context";

const apiRequest =
  process.env.NODE_ENV === "production"
    ? "https://www.entrancezone.com/api/v1/apply/api-data"
    : "https://beta.entrancezone.com/api/v1/apply/api-data";

const Banner = ({ forms, filters, isMobile, dataLoading }) => {
  const { popupCollegeData, storePopupData } = useContext(Context);
  const [searchLoading, setSearchLoading] = useState(false);
  const [selectedStream, setSelectedStream] = useState(undefined);
  const [selectedCourse, setSelectedCourse] = useState(undefined);

  const handleSelectorChange = (value) => {
    const { selectedStream, selectedCourse } = value;
    handleSearchButton();
    setSelectedStream(selectedStream);
    setSelectedCourse(selectedCourse);
  };

  const handleSearchButton = () => {
    //Generating random number between 200-500
    const timing = Math.floor(Math.random() * 300) + 200;
    setSearchLoading(true);
    setTimeout(() => {
      setSearchLoading(false);
    }, timing);
  };

  useEffect(() => {
    if (!isEmpty(popupCollegeData)) return;

    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(apiRequest);
      storePopupData(response?.data?.data ?? {});
    } catch (error) {
      storePopupData({});
    }
  };

  return (
    <>
      <header className="banner">
        <div
          className={`banner__text-box ${
            isMobile ? "" : "banner__text-box-desktop"
          } `}>
          <h1 className="heading-primary">
            <span className="heading-primary--main">
              Explore Latest Admission Forms
            </span>
          </h1>
          <SelectorHandler
            filters={filters}
            dataUpdate={handleSelectorChange}
            searchLoader={handleSearchButton}
            isMobile={isMobile}
          />
        </div>
      </header>
      <ExamList
        forms={forms}
        selectedCourse={selectedCourse}
        selectedStream={selectedStream}
        searchLoading={searchLoading}
        dataLoading={dataLoading}
        isMobile={isMobile}
      />
    </>
  );
};

export default Banner;
