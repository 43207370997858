/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from "react";
import { enquireScreen } from "enquire-js";
import axios from "axios";
import ReactGA from "react-ga";
import Nav0 from "./components/Nav0";
import Banner from "./components/Banner";
import Footer1 from "./components/Footer1";
// import ExitIntent from "./components/ExitIntent";
import NewsLetter from "./components/NewsLetter";
import { Provider } from "./utils/Context";

import { Nav00DataSource, Footer10DataSource } from "./data.source";
import "./less/antMotionStyle.less";

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

const api =
  process.env.NODE_ENV === "production"
    ? "https://www.entrancezone.com/api/v1/apply"
    : "https://beta.entrancezone.com/api/v1/apply";

ReactGA.initialize("UA-83305228-6", {
  debug: process.env.NODE_ENV !== "production",
});
ReactGA.pageview(window.location.pathname + window.location.search);

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
      forms: [],
      filters: [],
      dataLoading: true,
    };
  }

  handleApiCall = async () => {
    const response = await axios.get(api);
    // Both requests are now complete
    const { data: forms, filters } = response.data.data;
    this.setState({ forms, filters, dataLoading: false });
  };

  componentDidMount() {
    this.handleApiCall();
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
  }

  render() {
    const { isMobile, filters, forms, dataLoading } = this.state;
    const children = [
      <Nav0
        id="Nav0_0"
        key="Nav0_0"
        dataSource={Nav00DataSource}
        isMobile={isMobile}
      />,
      // <ExitIntent key="ExitIntent_0" />,
      <Banner
        filters={filters}
        forms={forms}
        key="Banner0_0"
        isMobile={isMobile}
        dataLoading={dataLoading}
      />,
      <NewsLetter key="NewsLetter_0" />,
      <Footer1
        id="Footer1_0"
        key="Footer1_0"
        dataSource={Footer10DataSource}
        isMobile={isMobile}
      />,
    ];
    return (
      <div
        className="templates-wrapper"
        ref={(d) => {
          this.dom = d;
        }}>
        <Provider>{children}</Provider>
      </div>
    );
  }
}

export default App;
