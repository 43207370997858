import React, { useState, createContext } from "react";

export const Context = createContext();

export const Provider = ({ children }) => {
  const [popupCollegeData, setPopupCollegeData] = useState({});
  const [appliedIds, setAppliedIds] = useState([]);

  const storePopupData = (data) => {
    setPopupCollegeData(data);
  };

  const func_appliedIds = (id) => {
    const ids = [...appliedIds, id];
    setAppliedIds(ids);
  };

  return (
    <Context.Provider
      value={{
        popupCollegeData,
        storePopupData,
        func_appliedIds,
        appliedIds,
      }}>
      {children}
    </Context.Provider>
  );
};
